import { defineStore } from 'pinia';
import { reactive } from 'vue';

export interface iUserData {
    id: number; // 使用者id
    account: string; // 使用者帳號
    access_token: string;
    role: {
        id: number, // roles_id
        name: string, // roles_name
        image_type: number[], // image_type 廔管頁面要顯示哪幾個
        permissions: {
            id: number,
            name: string,
            icon: string,
            href: string,
        }[]
    }
}

// 宣告、並匯出user的State
export interface iUserState {
    isLogin: boolean; // 是否有登入
    data: iUserData
}

const defaultUserData: iUserData = {
    id: 0,
    account: '',
    access_token: '',
    role: {
        id: 0,
        name: '',
        image_type: [],
        permissions: []
    }
};

const useUserStore = defineStore('useUserStore', () => {
    const userState = reactive<iUserState>({
        isLogin: false,
        data: { ...defaultUserData }
    });

    // 登入
    const signIn = (data: iUserData) => {
        // 記錄登入者資訊
        userState.data = data;
        setToken(userState.data.access_token); // token 紀錄
        userState.isLogin = true;
    };

    const signOut = () => {
        userState.data = { ...defaultUserData };
        setToken(''); // 清空token
        userState.isLogin = false;
    };

    const setToken = (JWT: string) => {
        // userState.data.access_token = JWT;
        // userState.refreshToken = refreshToken;


        // 為了安全改成 cookie read only
        // document.cookie = `jwtToken=${JWT}; path=/; max-age=${60 * 60 * 24 * 7}`; // 紀錄 token 在 cookie
        // document.cookie = `refreshToken=${refreshToken}; path=/; max-age=${60 * 60 * 24 * 7}`; // 刷新用token
        // localStorage.setItem('refreshToken', refreshToken); // 刷新用token


        localStorage.setItem('access_token', JWT); // 紀錄 token 在 localStorage
        if (JWT === '') {
            localStorage.setItem('user_data', '');
        } else {
            localStorage.setItem('user_data', JSON.stringify(userState.data));
        }
    }

    return {
        userState,
        signIn,
        signOut,
        setToken,
        // isAuthenticated
    }
});

export default useUserStore;